import React from "react"
import styles from "../styles/resume.module.css"

export default () => {
    return(
            <div class={styles.resumeContainer}>
                <div className={styles.resumeHeading}>Resume</div>
                <div className={styles.introText + " lead"}>I work at Integrated DNA Technologies where I synthesize and QC oligonucleotides. My current interests include data science and web development, so I am attempting make a career transition into that area. </div>
                <div className={styles.introText + " lead"}>You can see my skills, employment history, and educational background below. You can look some of my work on the projects page and on Github. You can also download my resume as a pdf.</div>
                <h3 className={styles.resumeSectionHeading}>Skills</h3>
                <table className="table">
                    <tbody>
                        <tr>
                            <th>Languages</th>
                            <td>JavaScript(<a href="https://nodejs.org/en/">Node.js</a>), <a href="https://www.python.org">Python</a>, <a href="https://www.java.com/en/">Java</a>, NoSQL(<a href="https://www.mongodb.com">MongoDB</a>)</td>
                        </tr>
                        <tr>
                            <th>Frameworks</th>
                            <td><a href="https://expressjs.com">Express.js</a>, <a href="https://reactjs.org">Reactjs</a>, <a href="https://jquery.com">jQuery</a>, <a href="https://getbootstrap.com">Bootstrap</a>, <a href="http://www.numpy.org">NumPy</a>, <a href="https://www.scipy.org">SciPy</a>, <a href="https://matplotlib.org">matplotlib</a></td>
                        </tr>
                        <tr>
                            <th>Tools</th>
                            <td><a href="https://git-scm.com">Git</a>, <a href="https://www.vim.org">Vim</a>, <a href="https://maven.apache.org">Maven</a>, HTTP APIs</td>
                        </tr>
                        <tr>
                            <th>DevOps</th>
                            <td><a href="https://aws.amazon.com">AWS</a>(EC2, S3, CloudFront), <a href="https://www.nginx.com">Nginx</a>, SSH</td>
                        </tr>
                        <tr>
                            <th>Testing</th>
                            <td><a href="https://junit.org/junit5/">JUnit</a>(Java), <a href="https://mochajs.org">Mocha</a>(JavaScript)</td>
                        </tr>
                    </tbody>
                </table>
                <h3 className={styles.resumeSectionHeading}>Experience</h3>
                <div className="font-weight-bold">Production Scientist II at Integrated DNA Technologies</div>
                <div className={styles.datesText}>November 2014-Present</div>
                <div className={styles.roleDescription}>Currently working in a lab that produces custom DNA oligonucleotides. I have developed various tools to streamline the production process including:</div>
                <ul className="my-2">
                    <li>vCheck: A GUI JavaFX application to streamline a quality control process that gives employees a visual way of passing/failing final product.</li>
                    <li>Patches: A command line tool for merging multiple text data files into a single, aggregate file.</li>
                </ul>
                <div className={styles.roleDescription}>My primary duties include synthesizing, purifying and performing quality control sampling and anlysis on oligonucleotides for academic and industrial use.</div>
                <h3 className={styles.resumeSectionHeading}>Projects</h3>
                <div className="font-weight-bold">Pilcrow</div>
                <div className={styles.roleDescription}>A web application where registered users take turns writing to a writing a story. It uses a MERN stack that presists users, threads and entrys with an API built with MongoDB and Express.js. The front-end is rendered with a mixture of static HTML/CSS using pug, as well as ReactJS for the dynamic components.</div>
                <h3 className={styles.resumeSectionHeading}>Education</h3>
                <div className="font-weight-bold mt-2">Bachelor's Degree (B.S.), Physics</div>
                <div className="">University Of California, Santa Barbara</div>
                <div className="font-weight-bold mt-2">Postbaccalaureate Study, Computer Science</div>
                <div className="pb-5">San Diego State University</div>
            </div>
    )
}